import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import packageJson from "../package.json";
import PrivateRoute from "./privateRoute";
import IntercomLoader from "./components/IntercomeLoader"; // Adjust the path as necessary
import routes from "./routes";

global.appVersion = packageJson.version;
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const SignupSuccess = lazy(() => import("./pages/SignupSuccess"));
const PendingApproval = lazy(() => import("./pages/PendingApproval"));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch meta.json");
        }
        return response.json();
      })
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
          window.location.reload();
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching meta.json:", error);
      });
  }, []);

  return (
    <Router>
      <AccessibleNavigationAnnouncer />
      <IntercomLoader />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/pending-approval" element={<PendingApproval />} />

        {/* Root redirect */}
        <Route path="/" element={<Navigate to="/login" replace />} />

        {/* Protected routes */}
        <Route path="/app/*" element={<PrivateRoute Component={Layout} />} />
      </Routes>
    </Router>
  );
}

export default App;
