import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { Spinner } from "@nextui-org/react";

const PrivateRoute = ({ Component }) => {
  const { auth } = useContext(AuthContext);
  const { loading } = auth;

  // Show loading state while checking auth
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="lg" color="default" />
      </div>
    );
  }

  // Redirect to login if not authenticated
  if (!auth?.data) {
    return <Navigate to="/login" replace />;
  }

  // Render protected component if authenticated
  return <Component />;
};

export default PrivateRoute;
