import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Page404 = lazy(() => import("../pages/404"));
const Blank = lazy(() => import("../pages/Blank"));
const ViewProfile = lazy(() => import("../pages/ViewProfile"));
const NewProfile = lazy(() => import("../pages/NewProfile"));

const ListProfiles = lazy(() => import("../pages/ListProfiles"));
const MyChannels = lazy(() => import("../pages/MyChannels"));
const JoinedChannels = lazy(() => import("../pages/JoinedChannels"));
const BrowseChannels = lazy(() => import("../pages/BrowseChannels"));
const NewChannel = lazy(() => import("../pages/NewChannel"));
const ViewChannel = lazy(() => import("../pages/ViewChannel"));

const ListPosts = lazy(() => import("../pages/ListPosts"));
const CreateProfile = lazy(() => import("../pages/CreateProfile"));
const ConnectLinkedin = lazy(() => import("../pages/ConnectLinkedin"));
const ViewPost = lazy(() => import("../pages/ViewPost"));
const PostAnalytics = lazy(() => import("../pages/PostAnalytics"));
// const PostEngage = lazy(() => import("../pages/PostEngage"));

const NewPost = lazy(() => import("../pages/NewPost"));
const Settings = lazy(() => import("../pages/Settings"));

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "settings",
    component: Settings,
  },
  {
    path: "accounts",
    component: Settings,
  },
  {
    path: "plan",
    component: Settings,
  },
  {
    path: "openai",
    component: Settings,
  },
  {
    path: "404",
    component: Page404,
  },
  {
    path: "blank",
    component: Blank,
  },
  {
    path: "profiles/view-profile/:id",
    component: ViewProfile,
  },
  {
    path: "profiles/new-profile",
    component: NewProfile,
  },
  {
    path: "profiles",
    component: ListProfiles,
  },
  {
    path: "profiles/create-new-profile",
    component: CreateProfile,
  },
  {
    path: "profiles/create-new-profile/:profile_id",
    component: ConnectLinkedin,
  },
  {
    path: "my-channels",
    component: MyChannels,
  },
  {
    path: "joined-channels",
    component: JoinedChannels,
  },
  {
    path: "joined-channels/view-channel/:id/:type",
    component: ViewChannel,
  },
  {
    path: "browse-channels",
    component: BrowseChannels,
  },
  {
    path: "browse-channels/view-channel/:id/:type",
    component: ViewChannel,
  },
  {
    path: "new-channel",
    component: NewChannel,
  },
  {
    path: "my-channels/view-channel/:id/:type",
    component: ViewChannel,
  },
  {
    path: "posts",
    component: ListPosts,
  },
  {
    path: "posts/new-post",
    component: NewPost,
  },
  {
    path: "posts/view-post/:id",
    component: ViewPost,
  },
  {
    path: "linkedin-analytics",
    component: PostAnalytics,
  },
  // {
  //   path: "/post-engage",
  //   component: PostEngage,
  // },
];

export default routes;
