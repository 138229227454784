import React from "react";
import { Spinner } from "@nextui-org/react";

function ThemedSuspense() {
  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner size="lg" color="default" />
    </div>
  );
}

export default ThemedSuspense;
